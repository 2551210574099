<template>
    <div class="page-layout-container">
        <div class="page-layout-main">
            <FullPageLoadingSpinner :show="pageIsLoading" />

            <template v-if="!pageIsLoading">
                <PageHeading :breadcrumbs="breadcrumbs" title="Categories" />

                <div class="category-content">
                    <CategoryTile
                        v-for="(category, index) in categories"
                        :key="index"
                        :disabled="category.id === 1"
                        class="category-content__tile"
                        :data="category"
                        @click="onEditCategory(category.id)"
                    />

                    <SelectButton
                        key="add-category-button"
                        class="category-content__add-button category-content__tile"
                        icon="plus-2"
                        @click="onAddCategory($event)"
                    >
                        Add new category
                    </SelectButton>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import FullPageLoadingSpinner from '@/components/ui/FullPageLoadingSpinner';
import PageHeading from '@/components/ui/PageHeading';
import { Breadcrumb } from '@/store/models/breadcrumb.dto.ts';
import SelectButton from '@/components/ui/SelectButton';
import CategoryTile from '@/components/CategoryTile';
import { LoadingFlag } from '@/store/enums/loadingIds.enum.ts';
import { router } from '../router';

export default {
    name: 'Category',
    components: {
        FullPageLoadingSpinner,
        PageHeading,
        SelectButton,
        CategoryTile,
    },
    data() {
        return {
            breadcrumbs: [],
        };
    },
    computed: {
        ...mapGetters('category', {
            categories: 'categories',
        }),
        pageIsLoading() {
            return this.$wait.is(LoadingFlag.ChannelGet);
        },
    },
    created() {
        this.setBreadcrumbs();
    },
    async mounted() {
        await this.getCategories();
    },
    methods: {
        ...mapActions('category', ['getCategories']),
        setBreadcrumbs() {
            const breadcrumbs = [];
            const categoryLink = { name: 'category' };
            breadcrumbs.push(new Breadcrumb('categories', categoryLink));
            this.breadcrumbs = breadcrumbs;
        },
        onAddCategory(event) {
            router.push('/add-category');
        },
        onEditCategory(id) {
            router.push(`/edit-category/${id}`);
        },
    },
};
</script>

<style lang="scss" scoped>
.category-content {
    margin-left: -15px;
    margin-right: -15px;
    display: flex;
    flex-wrap: wrap;

    &__tile {
        margin: 15px;
    }

    &__add-button {
        border: 1px dotted $container-default-border-color;
        width: 240px;
        height: 230px;
        border-radius: 15px;
        font-size: 14px;
        color: $table-row-color;
    }
}
</style>
