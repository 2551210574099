<template>
    <div v-if="!disabled" class="category-tile" @click="$emit('click', $event)">
        <div class="category-tile__image">
            <img class="image" :src="data.image" alt="Category image" />
            <div class="image-overlay" :style="{ background: `${data.color}` }"></div>
        </div>
        <div class="category-tile__details">
            <div class="category-tile__text">
                <div class="category-tile__text-name">{{ data.name }}</div>
                <div class="category-tile__text-description">{{ data.description }}</div>
            </div>
            <div class="category-tile__color" :style="{ background: `${data.color}` }"></div>
            <div class="category-tile__channel-list">
                <Avatar
                    v-for="(channel, index) in data.channelList.slice(0, 6)"
                    :key="index"
                    class="channel-avatar"
                    :image-url="channel.image"
                />
                <span
                    v-if="data.channelList && data.channelList.length > 5"
                    :style="{ background: `${data.color}` }"
                    class="avatar-ellipse channel-avatar"
                    >...</span
                >
            </div>
        </div>
    </div>
</template>

<script>
import Avatar from '@/components/ui/Avatar';

export default {
    name: 'CategoryTile',
    components: {
        Avatar,
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
.category-tile {
    justify-content: center;
    width: 240px;
    height: 230px;
    border-radius: 15px;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    border: 1px dotted $container-default-border-color;
    overflow: hidden;

    &:hover,
    &:focus {
        background: darken($select-button-background-color, 3%);
    }

    &__image {
        display: block;
        max-height: 125px;
        width: 100%;
        height: 50%;
        position: relative;

        .image {
            object-fit: cover;
        }

        .image,
        .image-overlay {
            height: 100%;
            width: 240px;
        }

        .image-overlay {
            position: absolute;
            bottom: 0;
            opacity: 0.5;
        }
    }

    &__details {
        width: 100%;
        height: 50%;
    }

    &__text {
        color: $table-row-color;
        font-size: 14px;
        padding: 10px 15px 0 15px;
        text-align: left;
        max-width: 210px;
        height: 64px;
        &-name {
            font-weight: $font-weight-bold;
        }
        &-description {
            max-height: 40px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }

    &__color {
        position: relative;
        width: 10px;
        height: 10px;
        left: 210px;
        bottom: 50px;
        border-radius: 50%;
    }

    &__channel-list {
        text-align: left;
        width: auto;
        padding: 0 15px;
        .channel-avatar {
            margin-right: -6px;
        }
        .avatar-ellipse {
            $size: 32px;
            height: $size;
            width: $size;
            border-radius: $size;
            border: 1px solid $inconvo-messenger-avatar-border-color;
            padding: 3px 8px;
            display: inline-block;
            text-align: center;
            color: white;
            letter-spacing: 2px;
            font-size: 16px;
        }
    }
}
</style>
